<template>
  <router-view />
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',
  data: () => ({
  }),
  watch: {
    $route: function () {
      // this.setupUser()
    }
  },
  created () {
    this.setupUser()
  },
  methods: {
    ...mapActions('PROJECTS', ['SET_USER']),
    async setupUser () {
      this.authenticated = await this.$auth.isAuthenticated()
      const accessToken = await this.$auth.getAccessToken()
      if (this.authenticated && accessToken) {
        try {
          const claims = await this.$auth.getUser()
          // console.log(claims)
          this.SET_USER({ claims, accessToken })
        } catch (error) {
          console.error(error)
        }
      } else {
        // this.LOGOUT()
        // this.$router.push({ path: '/login' })
      }
    }
  }
}
</script>
