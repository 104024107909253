import _ from 'lodash'
import projectImg1 from '@/assets/project1.png'

import { getProjects, getStreams, getTags, getPlatforms, getRenderPipelines, getUsers } from '@/api'

const defaultState = () => {
  return {
    loading: {
      projects: false,
      users: false
    },
    projects: {},
    tags: {},
    streams: {},
    platforms: {},
    renderPipelines: {},
    projectImg1,
    user: {
      name: '',
      claims: {},
      accessToken: {}
    },
    users: {}
  }
}
const state = defaultState()

const mutations = {
  SET_LOADING (state, payload) {
    state.loading[payload.key] = payload.value
  },
  SET_PROJECTS (state, payload) {
    state.projects = payload
  },
  SET_TAGS (state, payload) {
    state.tags = payload
  },
  SET_PLATFORMS (state, payload) {
    state.platforms = payload
  },
  SET_RENDER_PIPELINES (state, payload) {
    state.renderPipelines = payload
  },
  SET_STREAMS (state, payload) {
    state.streams = payload
  },
  SET_USER (state, auth) {
    state.user.name = auth.claims.name
    state.user.claims = auth.claims
    state.user.accessToken = auth.accessToken
  },
  SET_USERS (state, payload) {
    state.users = payload
  }
}

const actions = {
  async getProjects ({ commit }) {
    commit('SET_LOADING', { key: 'projects', value: true })
    try {
      const projects = await getProjects()
      commit('SET_PROJECTS', projects)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'projects', value: false })
  },
  async getTags ({ commit }) {
    commit('SET_LOADING', { key: 'tags', value: true })
    try {
      const tags = await getTags()
      commit('SET_TAGS', tags)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'tags', value: false })
  },
  async getStreams ({ commit }) {
    commit('SET_LOADING', { key: 'streams', value: true })
    try {
      const streams = await getStreams()
      // streams.reverse()
      commit('SET_STREAMS', streams)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'streams', value: false })
  },
  async getPlatforms ({ commit }) {
    commit('SET_LOADING', { key: 'platforms', value: true })
    try {
      const platforms = await getPlatforms()
      commit('SET_PLATFORMS', platforms)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'platforms', value: false })
  },
  async getRenderPipelines ({ commit }) {
    commit('SET_LOADING', { key: 'renderPipelines', value: true })
    try {
      const renderPipelines = await getRenderPipelines()
      commit('SET_RENDER_PIPELINES', renderPipelines)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'renderPipelines', value: false })
  },
  SET_USER ({ commit }, auth) {
    commit('SET_USER', auth)
  },
  async getUsers ({ commit }) {
    commit('SET_LOADING', { key: 'users', value: true })
    try {
      const users = await getUsers()
      commit('SET_USERS', users)
    } catch (error) {
      console.error(error)
    }
    commit('SET_LOADING', { key: 'users', value: false })
  }
  // async setUser (context, auth) {
  //   this.claims = await auth.getUser()
  //   const user = {
  //     auth_id: this.claims.sub,
  //     email: this.claims.email,
  //     name: this.claims.name,
  //   }
  //   if (Object.keys(state.user).length < 1) {
  //     const accessToken = await auth.getAccessToken()
  //     return axios.post(
  //         config.resourceServer.users, user,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`
  //           }
  //         }
  //       )
  //   }
  //   context.commit("SET_USER", user)
  // },
}

const getters = {
  projects: (state) => {
    return state.projects
  },
  projectsArray: (state) => {
    return _.values(state.projects)
  },
  projectsArrayIsEmpty: (state) => {
    return _.isEmpty(state.projects)
  },
  projectsArrayIsNotEmpty: (state) => {
    return !_.isEmpty(state.projects)
  },
  tags: (state) => state.tags,
  streams: (state) => state.streams,
  tagsArray: (state) => _.values(state.tags),
  tagsArrayIsEmpty: (state) => _.isEmpty(state.tags),
  tagsArrayIsNotEmpty: (state) => !_.isEmpty(state.tags),
  platforms: (state) => state.platforms,
  platformsArray: (state) => _.values(state.platforms),
  platformsArrayIsEmpty: (state) => _.isEmpty(state.platforms),
  platformsArrayIsNotEmpty: (state) => !_.isEmpty(state.platforms),
  renderPipelines: (state) => state.renderPipelines,
  renderPipelinesArray: (state) => _.values(state.renderPipelines),
  renderPipelinesArrayIsEmpty: (state) => _.isEmpty(state.renderPipelines),
  renderPipelinesArrayIsNotEmpty: (state) => !_.isEmpty(state.renderPipelines),
  user: (state) => state.user,
  users: (state) => state.users
  // getUser(state) {
  //   return state.user;
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
