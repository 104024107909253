import Vue from 'vue'
import Vuex from 'vuex'
//  import VuexPersist from 'vuex-persist'

const modules = {}

const files = require.context('.', false, /\.store\.js$/)
files.keys().forEach(key => {
  if (key === './index.js') return
  modules[key.replace(/(\.\/|\.store\.js)/g, '').toUpperCase()] = files(key).default
})

Vue.use(Vuex)

/*  const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  //storage: window.localStorage,
  //modules: ['STARRED_PROJECTS'],
})  */

export default new Vuex.Store({
  modules,
  //  plugins: [vuexLocalStorage.plugin],
  strict: process.env.NODE_ENV !== 'production'
})
