import _ from 'lodash'
import { instance, instance2 } from './plugins/api'

export const getGeneric = async (url, params) => {
  try {
    const response = await instance.get(url, { params })
    return response.data.data
  } catch (error) {
    const out = _.get(error, 'response.data.error', error)
    return console.error(out)
  }
}

export const postGeneric = async (url, body) => {
  try {
    const response = await instance.post(url, body)
    return response.data.data
  } catch (error) {
    const out = _.get(error, 'response.data.error', error)
    return console.error(out)
  }
}

export const postGeneric2 = async (url, body) => {
  try {
    const response = await instance2.post(url, body)
    return response.data.data
  } catch (error) {
    const out = _.get(error, 'response.data.error', error)
    return console.error(out)
  }
}

export const getTags = (data) => getGeneric('/tags', data)
export const getUsers = (data) => getGeneric('/users', data)
export const getStreams = (data) => getGeneric('/streams', data)
export const getComments = (id) => getGeneric(`/comments/${id}`)
export const getImage = (id) => getGeneric(`/image/${id}`)
export const getVerifiedVersions = (id) => getGeneric(`/versions/${id}`)
export const getProjectByID = (id) => getGeneric(`/project/${id}`)
export const getPlatforms = () => getGeneric('/platforms')
export const getRenderPipelines = () => getGeneric('/renderpipelines')
export const getProjects = () => getGeneric('/repositoryprojects')

export const createProject = (body) => postGeneric('/repositoryproject', body)
export const createProjectFlow = (body) => postGeneric('/createProjectFlow', body)
export const addTag = (body) => postGeneric('/addTag', body)
export const addComment = (body) => postGeneric('/addComment', body)
export const addVerifiedVersion = (body) => postGeneric('/addVerifiedVersion', body)
export const addImage = (body) => postGeneric('/addImage', body)
export const projectUpdateFlow = (body) => postGeneric('/projectUpdateFlow', body)
export const uploadImage = (body) => postGeneric('/upload', body)
export const uploadImage2 = (body) => postGeneric2('/upload', body)
export const addImageLink = (body) => postGeneric('/addImageLink', body)
export const addNewImageLink = (body) => postGeneric('/addNewImageLink', body)
