import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import vuetify from './plugins/vuetify'
import './plugins/base'
import './plugins/api'

import VueMarkdown from '@adapttive/vue-markdown'
import 'github-markdown-css/github-markdown.css'

import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'

import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import moment from 'moment'
import VueMoment from 'vue-moment'

import lodash from 'lodash'
import VueLodash from 'vue-lodash'

import VideoBg from 'vue-videobg'

Vue.config.productionTip = false

const oktaAuth = new OktaAuth({
  issuer: process.env.OKTA_DOMAIN,
  clientId: process.env.OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`
})

Vue.use(OktaVue, { oktaAuth })

Vue.use(VueAxios, axios)
Vue.use(VueMoment, { moment })
Vue.use(VueLodash, { lodash })

Vue.use(mavonEditor)

Vue.component('vue-markdown', VueMarkdown)
Vue.component('video-bg', VideoBg)

Vue.filter('utcAsLocal', (value, format) => {
  return moment.unix(value).local().format(format)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
