import Vue from 'vue'
import axios from 'axios'

export const instance = axios.create({
  baseURL: process.env.LOCAL ? 'http://localhost:8000/' : `https://api.qa-project-repository.${process.env.CLUSTER}.ds.unity3d.com`
})

instance.interceptors.request.use(
  async (request) => {
    const accessToken = await Vue.prototype.$auth.getAccessToken()
    request.headers = { Authorization: `Bearer ${accessToken}`, ...request.headers }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const instance2 = axios.create({
  baseURL: process.env.LOCAL ? 'http://localhost:8000/' : `https://api.qa-project-repository.${process.env.CLUSTER}.ds.unity3d.com`,
  headers: {
    'Content-Type': 'multipart/form-data'
  }
})

instance2.interceptors.request.use(
  async (request) => {
    const accessToken = await Vue.prototype.$auth.getAccessToken()
    request.headers = { Authorization: `Bearer ${accessToken}`, ...request.headers }
    return request
  },
  (error) => {
    return Promise.reject(error)
  }
)

Vue.prototype.$api = instance
Vue.prototype.$api2 = instance2
