import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'Projects Repository',
          component: () => import('@/views/dashboard/Dashboard')
        }
      ]
    },
    {
      path: '/project/:id',
      component: () => import('@/views/project/Index'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'Project Information',
          component: () => import('@/views/project/Project')
        }
      ]
    },
    {
      path: '/login',
      component: () => import('@/views/Home')
    },
    {
      path: '/implicit/callback',
      component: () => import('@/views/LoginCallback.vue')
    },
    {
      path: '/error',
      component: () => import('@/views/ErrorView.vue')
    },
    {
      path: '*',
      beforeEnter: (to, from, next) => {
        return next({ path: '/error', query: { message: 'Page not found' } })
      }
    }
  ]
})

const onAuthRequired = async (from, to, next) => {
  if (from.matched.some(record => record.meta.requiresAuth)) {
    const isAuthenticated = await Vue.prototype.$auth.isAuthenticated()
    if (!isAuthenticated) {
      return next({
        path: '/login',
        query: { redirect: from.fullPath }
      })
    }
  }
  return next()
}

router.beforeEach(onAuthRequired)

export default router
